<template>
  <div class="dashboard-editor-container home" id="adminHome" style="height:100%;">
    <!-- 顶部 -->
    <el-card>
      <el-row>
        <p class="Dtop_p">
          <span class="Dtop_title">当前记录</span>
          <span class="Dtop_second">{{timeMessage}}</span> 
          <span class="Dtop_describe" >之后更新数据</span> 
        </p>
        <el-col :span="10">
          <div class="Dtop_l" style="margin-bottom:20px;">
            <p style="margin-top:0;">{{FormateTime(nowDate)}}</p>
            <p class="Dtop_l_num" style="margin:20px 0;">{{readMeterNum}}</p>
            <p style="margin-bottom:20px;margin-top:0px;">今日抄表数量</p>
          </div>
          <el-row style="text-align:center">
            <el-col :span="6" class="topNum">
              <p class="topNum_title">区域注册数
                <el-tooltip class="item" effect="dark" content="区域注册数" placement="bottom-start">
                  <i class="el-icon-question" style="color:#1a96d4"></i>
                </el-tooltip>
              </p>
              <p class="topNum_num">{{areaRegistered}}个</p>
            </el-col>
            <el-col :span="6" class="topNum">
              <p class="topNum_title">集中器总数
                <el-tooltip class="item" effect="dark" content="集中器总数" placement="bottom-start">
                  <i class="el-icon-question" style="color:#1a96d4"></i>
                </el-tooltip>
              </p>
              <p class="topNum_num">{{concentratorNum}}个</p>
            </el-col>
            <el-col :span="6" class="topNum">
              <p class="topNum_title">入库远传表总数
                <el-tooltip class="item" effect="dark" content="入库远传表总数" placement="bottom-start">
                  <i class="el-icon-question" style="color:#1a96d4"></i>
                </el-tooltip>
              </p>
              <p class="topNum_num">{{farEastoneMeterNum}}个</p>
            </el-col>
            <el-col :span="6" class="topNum">
              <p class="topNum_title">当月开户数
                <el-tooltip class="item" effect="dark" content="截止当前时间开户数量" placement="bottom-start">
                  <i class="el-icon-question" style="color:#1a96d4"></i>
                </el-tooltip>
              </p>
              <p class="topNum_num">{{openAccont}}个</p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :span="14">
          <p style="text-align:center;margin:0;color: #787a7d;font-size: 14px;">最近7天抄表记录</p>
          <div id="realChart" :style="{width: '100%', height: uniformHeight + 'px',}"></div>
        </el-col>
      </el-row>
    </el-card>
    <!-- 中部 -->
    <el-row style="margin:10px 0;" :gutter="8">
      <!-- 环形图 -->
      <el-col :span="8">
        <el-card class="half_card">
          <div class="title_nav">{{ringChartData.title}}</div>
          <div class="range">{{ringChartData.beginDate}}<span>~</span>{{ringChartData.endDate}} | {{ringChartData.date}}</div>
          <div class="h_c_charts">
            <div id="ringChart" :style="{width: '100%', height: uniformHeight + 'px',}"></div>
          </div>
        </el-card>
      </el-col>
      <!-- 条形图 -->
      <el-col :span="8">
        <el-card class="half_card">
          <div class="title_nav">{{stripesChartData.title}}</div>
          <div class="range">{{stripesChartData.beginDate}}<span>~</span>{{stripesChartData.endDate}} | {{stripesChartData.date}}</div>
          <div class="h_c_charts">
            <div id="stripesChart" :style="{width: '100%', height: uniformHeight + 'px',}"></div>
          </div>
        </el-card>
      </el-col>
      <!-- 柱形图 -->
      <el-col :span="8">
        <el-card class="half_card">
          <div class="title_nav">{{barChartData.title}}</div>
          <div class="range">{{barChartData.beginDate}}<span>~</span>{{barChartData.endDate}} | {{barChartData.date}}</div>
          <div class="h_c_charts">
            <div id="barChart" :style="{width: '100%', height: uniformHeight + 'px',}"></div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <!-- 底部 -->
    <div>
      <!-- <basic-table :tableHeight="270" :tableHeaderList="firstHeaderList" :tableData="tableData" :hasPagination='false'></basic-table > -->

      <u-table use-virtual ref="mainTable"  showBodyOverflow="tooltip" showHeaderOverflow="title" :height="uniformHeight" :row-height="30" :data="tableData"  border style="width: 100%;" >
        <u-table-column label="告警信息"  show-overflow-tooltip align="center" min-width="200">
          <template slot-scope="scope">
            <span style="color:red;">{{scope.row.alarmMsg}}</span>
          </template>
        </u-table-column>
        <u-table-column label="告警时间"  show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.createTime}}</span>
          </template>
        </u-table-column>
        <u-table-column label="用户名称"  show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.userName}}</span>
          </template>
        </u-table-column>
        <u-table-column label="表编号"  show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.surfaceNum}}</span>
          </template>
        </u-table-column>
        <u-table-column label="表地址"  show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.surfaceAddress}}</span>
          </template>
        </u-table-column>
        <u-table-column label="区域名称"  show-overflow-tooltip align="center">
          <template slot-scope="scope">
            <span>{{scope.row.areaName}}</span>
          </template>
        </u-table-column>
        <u-table-column label="安装地址"  show-overflow-tooltip align="center" min-width="160">
          <template slot-scope="scope">
            <span>{{scope.row.installAddress}}</span>
          </template>
        </u-table-column>
        
      </u-table>
    </div>
  </div>
</template>


<script>
  import * as basicDataApi from '@/api/basicDataApi/basicDataApi'//基础资料接口
  import * as echarts from 'echarts';
  
  import {mapState,mapActions} from 'vuex'
  export default {
    name: 'dashboard-admin',
    components: {    
    },
    data() {
      return {
        uniformHeight:200,//计算等分高度
        seconds:60,//计时刷新
        nowDate:'',//实时时间
        readMeterNum:28680,//今日抄表数量
        areaRegistered:65,
        concentratorNum:0,
        farEastoneMeterNum:33605,
        openAccont:655,
        chooseTime:'',//选择时间
        ringChartData:{//环形图
          title:'抄表率统计',
          beginDate:'2022-11-18',
          endDate:'2022-11-25',
          date:'过去 1 天',
        },
        stripesChartData:{//条形图
          title:'区域未抄表情况统计',
          beginDate:'2022-11-18',
          endDate:'2022-11-25',
          date:'过去 1 天',
        },
        barChartData:{//柱状图
          title:'抄表数量',
          beginDate:'2022-11-18',
          endDate:'2022-11-25',
          date:'过去 1 天',
        },
        tableData:[//table
          {reson:'持续34天未上报异常',creatTime:'2022-11-20 10:24:43',memterNum:'210211743',address:'华府08号楼1单元1202',doorNum:'102',},
          {reson:'持续8天未上报异常',creatTime:'2022-11-21 10:24:43',memterNum:'201220642',address:'万科翡翠湾05号楼2单元0601',doorNum:'2104',},
          {reson:'持续5天未上报异常',creatTime:'2022-11-22 10:24:43',memterNum:'210131967',address:'万科翡翠湾15号楼1单元0201',doorNum:'1102',},
          {reson:'持续14天未上报异常',creatTime:'2022-11-23 10:24:43',memterNum:'220201738',address:'德尚世家1号楼940号网点',doorNum:'302',},
          {reson:'持续53天未上报异常',creatTime:'2022-11-24 10:24:43',memterNum:'220201808',address:'德尚世家1号楼946号网点',doorNum:'1503',},
          {reson:'持续34天未上报异常',creatTime:'2022-11-25 10:24:43',memterNum:'220201734',address:'德尚世家02号楼2-101号网点',doorNum:'201',},
          {reson:'持续48天未上报异常',creatTime:'2022-11-25 10:24:43',memterNum:'211011886',address:'德尚世家04号楼2单元0902',doorNum:'2002',},
        ],
        realChart:'',//顶部折现图-用于屏幕放大缩小重新渲染
        ringChart:'',//环形图-用于屏幕放大缩小重新渲染
        stripesChart:'',//条形图-用于屏幕放大缩小重新渲染
        barChart:'',//柱状图-用于屏幕放大缩小重新渲染
        maxTime:5 * 60,//最大倒计时
        timeMessage:'',//倒计时提示
        firstHeaderList:[],
      }
    },
    created() {
      //获取当前时间
      var myDate = new Date();
      this.nowTime = this.$basicFun.dataFormat(myDate,'yyyy-MM-dd');
      this.ringChartData.beginDate = this.ringChartData.endDate = this.stripesChartData.beginDate = this.stripesChartData.endDate = this.barChartData.beginDate = this.barChartData.endDate = this.nowTime
    },
    computed:{
      ...mapState(["app"]),
      sidebarOpened() {
        return this.app.sidebar.opened;
      }
    },
    mounted() {

      //计算等分高度
      this.$nextTick(()=>{
        var allHeight
        let ele = document.getElementById("adminHome");
        allHeight = ele.offsetHeight - 54 - 41 - 18 - 20 - 30 //减去顶部标题+padding，减去中部标题+padding，减去中部日期行,减去中部模块margin，减去多余高度30
        this.uniformHeight = Math.ceil(allHeight/3)
        window.addEventListener('resize', () => { 
          this.Refresh()//监听屏幕尺寸变换刷新
          // let ele = document.getElementById("adminHome");
          // allHeight = ele.offsetHeight
          // this.uniformHeight = Math.ceil(allHeight/3)
          // this.realChart.resize();
          // this.ringChart.resize();
          // this.stripesChart.resize();
          // this.barChart.resize();
          // this.$forceUpdate()
        });
      })


      // 显示时间1s1s的显示
      let that = this
      this.getTimer = setInterval(function () {
        that.nowDate = new Date().toLocaleString()
      })
      
      this.getAllData();//获取所有图表数据
      
      this.countdown()//倒计时
      // echart屏幕放大缩小重新渲染
      // window.onresize = () => {
      //   let ele = document.getElementById("adminHome");
      //   var allHeight = ele.offsetHeight - 54 - 41 - 18 - 20 - 30 //减去顶部标题+padding，减去中部标题+padding，减去中部日期行,减去中部模块margin，减去多余高度30
      //   this.uniformHeight = Math.ceil(allHeight/3)
      //   this.realChart.resize();
      //   this.ringChart.resize();
      //   this.stripesChart.resize();
      //   this.barChart.resize();
      //   this.$forceUpdate()
      // };
    },
    methods: {
      //监听屏幕尺寸变换刷新
      Refresh() {
        this.$router.go(0);
      },
      getAllData(){//获取所有图表数据
        this.getHomeGetOverview();//获取概览数据
        this.getRealChart();//获取最近30分钟访问情况数据
        this.getRingChartData();//获取环形图数据
        this.getStripesChartData();//获取条形图数据
        this.getBarChartData();//获取柱状图图数据
        this.getWarningData();//获取报警信息
      },
      //获取概览数据
      getHomeGetOverview(){
        basicDataApi.homeGetOverview().then(response => {
          if(response.code == 200){
            this.readMeterNum = response.result.readNum
            this.areaRegistered = response.result.areaNum
            this.concentratorNum = response.result.concentratorNum
            this.farEastoneMeterNum = response.result.remoteMeterNum
            this.openAccont = response.result.newUserMeterNum
          }else{
            this.readMeterNum = ''
            this.areaRegistered = ''
            this.concentratorNum = ''
            this.farEastoneMeterNum = ''
            this.openAccont = ''
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
      },
      //获取最近30分钟访问情况数据
      getRealChart(){
        var d1,d2,xDatas
        basicDataApi.homeGetRealChart().then(response => {
          if(response.code == 200){
            xDatas = response.result.xDatas
            d1 = response.result.y1Datas
            d2 = response.result.y2Datas
            // 最近30分钟访问情况
            this.realChart = echarts.init(document.getElementById('realChart'));
            this.realChart.setOption({
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                top:'15%',
                bottom:'10%',
                right:'5%'
              },
              legend: {
                data: ['已抄表数', '未抄表数'],
                x:'right',      //可设定图例在左、右、居中
                y:'top',     //可设定图例在上、下、居中
              },
              xAxis: [
                {
                  type: 'category',
                  boundaryGap: false,//坐标轴两边留白
                  axisTick: {show: false},
                  data: xDatas
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  name:'已抄表数',
                  type: 'line',
                  itemStyle: {
                    color:'#00a7e2',
                  },
                  lineStyle: {
                    color: "#00a7e2",
                    width:1
                  },
                  areaStyle: { 
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(0,167,226,0.3)'}, { offset: 1,color: 'rgba(0,167,226,1)'}]),
                  },
                  data: d1
                },
                {
                  name:'未抄表数',
                  type: 'line',
                  itemStyle: {
                    color:'#b694f0',
                  },
                  lineStyle: {
                    color: "#b694f0",
                    width:1
                  },
                  areaStyle: { 
                    color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [{offset: 0,color: 'rgba(182,148,240,0.3)'},{offset: 1,color: 'rgba(182,148,240,1)'}]),
                  },
                  data: d2
                },
                
              ]
            })
          }else{
            xDatas = ''
            d1 = ''
            d2 = ''
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
        
        
      },
      //获取环形图数据
      getRingChartData(){
        var successCount,failCount,readRate
        basicDataApi.homeGetRingChart().then(response => {
          if(response.code == 200){
            successCount = response.result.successCount
            failCount = response.result.failCount
            readRate = response.result.readRate
            this.ringChart = echarts.init(document.getElementById('ringChart'));
            this.ringChart.setOption({
              tooltip: {
                trigger: 'item',
                formatter: "{a} <br/>{b} : {c} ({d}%)"
              },
              grid:{//图表位置
                x:0,
                y:30,
                x2:30,
                y2:50,
                containLabel: true,
              },
              graphic:{       //图形中间文字
                type:"text",
                left:"center",
                top:"center",
                style:{
                  text:readRate + "\n\n"+'抄见率',
                  textAlign:"center",
                  fill:"#010101",
                  fontSize: 16
                }
              },
              series: [
                {
                  name: '抄表率统计',
                  type: 'pie',
                  radius: ['40%', '60%'],  //图的大小
                  avoidLabelOverlap: false,
                  center: ['50%', '50%'], //图的位置，距离左跟上的位置
                  label: {
                    formatter: '{b|{b}：}{c}  {per|{d}%}  ',
                    backgroundColor: '#F6F8FC',
                    borderColor: '#00a7e2',
                    borderWidth: 1,
                    borderRadius: 4,
                    rich: {
                      b: {
                        color: '#4C5058',
                        fontSize: 14,
                        fontWeight: 'bold',
                        lineHeight: 33
                      },
                      per: {
                        color: '#fff',
                        backgroundColor: '#4C5058',
                        padding: [3, 4],
                        borderRadius: 4
                      }
                    }
                  },
                  data: [
                    {value: successCount, name: '已抄表总数',itemStyle: { color: '#00a7e2' },},
                    {value: failCount, name: '未抄表总数',itemStyle: { color: '#ff5c5a' },},
                    // {value: 580, name: '小程序',itemStyle: { color: '#ffbd65' },},
                    // {value: 484, name: '其他',itemStyle: { color: '#ff5c5a' },},
                  ],
                  itemStyle: {	// 自动显显示数据开始
                    label:{ 
                      show: true, 
                      formatter: '{b} : {c} ({d}%)' 
                    },
                    labelLine :{show:true,}
                  },
                }
              ]
            })
          }else{
            successCount = ''
            failCount = ''
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
        
      },
      //获取条形图数据
      getStripesChartData(){
        var sname,xDatas,yDatas
        basicDataApi.homeGetStripesChart().then(response => {
          if(response.code == 200){
            sname = response.result.name
            xDatas = response.result.xDatas
            yDatas = response.result.yDatas
            this.stripesChart = echarts.init(document.getElementById('stripesChart'));
            this.stripesChart.setOption({
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                x:0,
                y:30,
                x2:30,
                y2:50,
                containLabel: true,
              },
              legend: {
                data: [sname, ],
                x:'center',      //可设定图例在左、右、居中
                y:'bottom',     //可设定图例在上、下、居中
                padding:[0,0,10,0],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
              },
              xAxis: [
                {
                  type: 'value',
                  splitLine: {
                    show: false
                  },
                }
              ],
              yAxis: [
                {
                  type: 'category',
                  // data: ['中海锦城', '中海上和府', '府前花园', '滨湖万丽', '龙山海景', '德尚世家', '清泉寨城中城', '中海城里', '中海国际社区',],
                  data: yDatas,
                  axisTick: {
                    show: false,
                    alignWithLabel: false,
                    length: 5,
                  },
                  axisLine: {
                    show: false,
                  },
                  axisLabel: {  
                    interval:0,  
                    rotate:20  
                  },
                },
              ],
              series: [
                {
                  name: sname,
                  type: 'bar',
                  // data: [18203, 23489, 29034, 104970, 131744, 630230, 121594, 134141, 681807],
                  data: xDatas,
                  itemStyle: {
                    color:'#ffbd65',
                  },
                },
              ]
            })
          }else{
            sname = xDatas = yDatas = ''
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
        
      },
      //获取柱状图图数据
      getBarChartData(){
        var xDatas,yDatas
        basicDataApi.homeGetBarChart().then(response => {
          if(response.code == 200){
            xDatas = response.result.xDatas
            yDatas = response.result.yDatas
            this.barChart = echarts.init(document.getElementById('barChart'));
            this.barChart.setOption({
              tooltip: {
                trigger: 'axis',
              },
              grid:{//图表位置
                x:30,
                y:30,
                x2:30,
                y2:40,
                left:'10%'
              },
              legend: {
                // data: ['浏览量(PV)'],
                x:'center',      //可设定图例在左、右、居中
                y:'bottom',     //可设定图例在上、下、居中
                padding:[0,0,10,10],   //可设定图例[距上方距离，距右方距离，距下方距离，距左方距离]
              },
              xAxis: [
                {
                  type: 'category',
                  // data: ['区域注册数', '集中器总数', '入库远传表总数','当月开户数','今日抄表数量'],
                  data: xDatas,
                  axisLabel: {  
                    interval:0,  
                    rotate:20  
                  },
                }
              ],
              yAxis: [
                {
                  type: 'value'
                }
              ],
              series: [
                {
                  type: 'bar',
                  // data: [ 11, 22, 33, 34, 55,],
                  data: yDatas,
                  showBackground: true,//是否显示背景
                  backgroundStyle: {
                    color: 'rgba(0,167,226,0.1)'
                  },
                  barWidth:'30px',
                  itemStyle: {
                    color:'#00a7e2',
                  },
                },
              ]
            })
          }else{
            xDatas = ''
            yDatas = ''
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{})
        
      },
      //获取报警信息
      getWarningData(){
        basicDataApi.homeGetWarning().then(response => {
          if(response.code == 200){
            response.columnHeaders.forEach((item) => { // 首字母小写
              item.key = item.key.substring(0, 1).toLowerCase() + item.key.substring(1)
            })
            this.firstHeaderList = response.columnHeaders
            this.tableData = response.data
          }else{
            this.firstHeaderList = []
            this.tableData = []
            this.$notify({
              message: response.message,
              type: 'warning',
              duration: 2000
            });
          }
        }).catch(()=>{
          this.firstHeaderList = []
          this.tableData = []
        })
      },
      // 倒计时
      countdown(){
        var that = this
        var timer = setInterval(() => {
          var minutes = parseInt(that.maxTime / 60);
          var seconds = parseInt(that.maxTime % 60);
          that.timeMessage = minutes + "分" + seconds + "秒"
          that.maxTime--;
          if(that.maxTime<=0){
            clearInterval(timer);
            this.getRealData()
          }
        }, 1000)
        // var timer = setInterval(() => {
        //   that.seconds--;
        //   if(that.seconds<=0){
        //     clearInterval(timer);
        //     this.getRealData()
        //   }
        // }, 1000)
      },
      //获取实时数据
      getRealData(){
        this.maxTime = 5 * 60;
        // this.seconds = 60;
        this.getHomeGetOverview();//获取概览数据
        this.getRealChart();//获取最近30分钟访问情况数据
        this.getRingChartData();//获取环形图数据
        this.getStripesChartData();//获取条形图数据
        this.getBarChartData();//获取柱状图图数据
        this.getWarningData();//获取报警信息
        this.countdown();
      },
      //时间格式
      FormateTime(){
        var date=new Date();
        var year = this.formateTime(date.getFullYear())
        var month = this.formateTime(date.getMonth() + 1)
        var day = this.formateTime(date.getDate())
        var hour = this.formateTime(date.getHours())
        var minute = this.formateTime(date.getMinutes())
        var second = this.formateTime(date.getSeconds())
        var weekday = date.getDay();
        var weeks = new Array("星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六");
        var week = weeks[weekday];
        return year + '-' + month + '-' + day + " " + hour + ":" + minute + ":" + second + " " + week
      },
      formateTime(n){
        if(n<10){
          return '0'+n
        }else{
          return n
        }
      },
    },
    // 销毁定时器
    beforeDestroy() {
      if (this.getTimer) {
        clearInterval(this.getTimer)
      }
    },
    destroyed() {
      // 销毁监听屏幕尺寸变换刷新
      window.removeEventListener("resize", this.Refresh());
    },
    watch:{
      'sidebarOpened'(val){
        this.realChart.resize();
        this.ringChart.resize();
        this.stripesChart.resize();
        this.barChart.resize();
        
      }
    },
   
  }

</script>

<style rel="stylesheet/scss" lang="scss" scoped>
  .home{
    .topNum{
      p{margin: 0;}
      .topNum_title{
        font-size: 12px;
        color: #787a7d;
      }
      .topNum_num{
        margin: 8px 0;
        font-size: 20px;
        color: #121315;
      }
    }
  }

</style>
