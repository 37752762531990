var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "dashboard-editor-container home",
      staticStyle: { height: "100%" },
      attrs: { id: "adminHome" },
    },
    [
      _c(
        "el-card",
        [
          _c(
            "el-row",
            [
              _c("p", { staticClass: "Dtop_p" }, [
                _c("span", { staticClass: "Dtop_title" }, [_vm._v("当前记录")]),
                _c("span", { staticClass: "Dtop_second" }, [
                  _vm._v(_vm._s(_vm.timeMessage)),
                ]),
                _c("span", { staticClass: "Dtop_describe" }, [
                  _vm._v("之后更新数据"),
                ]),
              ]),
              _c(
                "el-col",
                { attrs: { span: 10 } },
                [
                  _c(
                    "div",
                    {
                      staticClass: "Dtop_l",
                      staticStyle: { "margin-bottom": "20px" },
                    },
                    [
                      _c("p", { staticStyle: { "margin-top": "0" } }, [
                        _vm._v(_vm._s(_vm.FormateTime(_vm.nowDate))),
                      ]),
                      _c(
                        "p",
                        {
                          staticClass: "Dtop_l_num",
                          staticStyle: { margin: "20px 0" },
                        },
                        [_vm._v(_vm._s(_vm.readMeterNum))]
                      ),
                      _c(
                        "p",
                        {
                          staticStyle: {
                            "margin-bottom": "20px",
                            "margin-top": "0px",
                          },
                        },
                        [_vm._v("今日抄表数量")]
                      ),
                    ]
                  ),
                  _c(
                    "el-row",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "el-col",
                        { staticClass: "topNum", attrs: { span: 6 } },
                        [
                          _c(
                            "p",
                            { staticClass: "topNum_title" },
                            [
                              _vm._v("区域注册数 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "区域注册数",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#1a96d4" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "topNum_num" }, [
                            _vm._v(_vm._s(_vm.areaRegistered) + "个"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "topNum", attrs: { span: 6 } },
                        [
                          _c(
                            "p",
                            { staticClass: "topNum_title" },
                            [
                              _vm._v("集中器总数 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "集中器总数",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#1a96d4" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "topNum_num" }, [
                            _vm._v(_vm._s(_vm.concentratorNum) + "个"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "topNum", attrs: { span: 6 } },
                        [
                          _c(
                            "p",
                            { staticClass: "topNum_title" },
                            [
                              _vm._v("入库远传表总数 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "入库远传表总数",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#1a96d4" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "topNum_num" }, [
                            _vm._v(_vm._s(_vm.farEastoneMeterNum) + "个"),
                          ]),
                        ]
                      ),
                      _c(
                        "el-col",
                        { staticClass: "topNum", attrs: { span: 6 } },
                        [
                          _c(
                            "p",
                            { staticClass: "topNum_title" },
                            [
                              _vm._v("当月开户数 "),
                              _c(
                                "el-tooltip",
                                {
                                  staticClass: "item",
                                  attrs: {
                                    effect: "dark",
                                    content: "截止当前时间开户数量",
                                    placement: "bottom-start",
                                  },
                                },
                                [
                                  _c("i", {
                                    staticClass: "el-icon-question",
                                    staticStyle: { color: "#1a96d4" },
                                  }),
                                ]
                              ),
                            ],
                            1
                          ),
                          _c("p", { staticClass: "topNum_num" }, [
                            _vm._v(_vm._s(_vm.openAccont) + "个"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c("el-col", { attrs: { span: 14 } }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "text-align": "center",
                      margin: "0",
                      color: "#787a7d",
                      "font-size": "14px",
                    },
                  },
                  [_vm._v("最近7天抄表记录")]
                ),
                _c("div", {
                  style: { width: "100%", height: _vm.uniformHeight + "px" },
                  attrs: { id: "realChart" },
                }),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-row",
        { staticStyle: { margin: "10px 0" }, attrs: { gutter: 8 } },
        [
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", { staticClass: "half_card" }, [
                _c("div", { staticClass: "title_nav" }, [
                  _vm._v(_vm._s(_vm.ringChartData.title)),
                ]),
                _c("div", { staticClass: "range" }, [
                  _vm._v(_vm._s(_vm.ringChartData.beginDate)),
                  _c("span", [_vm._v("~")]),
                  _vm._v(
                    _vm._s(_vm.ringChartData.endDate) +
                      " | " +
                      _vm._s(_vm.ringChartData.date)
                  ),
                ]),
                _c("div", { staticClass: "h_c_charts" }, [
                  _c("div", {
                    style: { width: "100%", height: _vm.uniformHeight + "px" },
                    attrs: { id: "ringChart" },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", { staticClass: "half_card" }, [
                _c("div", { staticClass: "title_nav" }, [
                  _vm._v(_vm._s(_vm.stripesChartData.title)),
                ]),
                _c("div", { staticClass: "range" }, [
                  _vm._v(_vm._s(_vm.stripesChartData.beginDate)),
                  _c("span", [_vm._v("~")]),
                  _vm._v(
                    _vm._s(_vm.stripesChartData.endDate) +
                      " | " +
                      _vm._s(_vm.stripesChartData.date)
                  ),
                ]),
                _c("div", { staticClass: "h_c_charts" }, [
                  _c("div", {
                    style: { width: "100%", height: _vm.uniformHeight + "px" },
                    attrs: { id: "stripesChart" },
                  }),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { attrs: { span: 8 } },
            [
              _c("el-card", { staticClass: "half_card" }, [
                _c("div", { staticClass: "title_nav" }, [
                  _vm._v(_vm._s(_vm.barChartData.title)),
                ]),
                _c("div", { staticClass: "range" }, [
                  _vm._v(_vm._s(_vm.barChartData.beginDate)),
                  _c("span", [_vm._v("~")]),
                  _vm._v(
                    _vm._s(_vm.barChartData.endDate) +
                      " | " +
                      _vm._s(_vm.barChartData.date)
                  ),
                ]),
                _c("div", { staticClass: "h_c_charts" }, [
                  _c("div", {
                    style: { width: "100%", height: _vm.uniformHeight + "px" },
                    attrs: { id: "barChart" },
                  }),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "u-table",
            {
              ref: "mainTable",
              staticStyle: { width: "100%" },
              attrs: {
                "use-virtual": "",
                showBodyOverflow: "tooltip",
                showHeaderOverflow: "title",
                height: _vm.uniformHeight,
                "row-height": 30,
                data: _vm.tableData,
                border: "",
              },
            },
            [
              _c("u-table-column", {
                attrs: {
                  label: "告警信息",
                  "show-overflow-tooltip": "",
                  align: "center",
                  "min-width": "200",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v(_vm._s(scope.row.alarmMsg)),
                        ]),
                      ]
                    },
                  },
                ]),
              }),
              _c("u-table-column", {
                attrs: {
                  label: "告警时间",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.createTime))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("u-table-column", {
                attrs: {
                  label: "用户名称",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.userName))])]
                    },
                  },
                ]),
              }),
              _c("u-table-column", {
                attrs: {
                  label: "表编号",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.surfaceNum))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("u-table-column", {
                attrs: {
                  label: "表地址",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.surfaceAddress))]),
                      ]
                    },
                  },
                ]),
              }),
              _c("u-table-column", {
                attrs: {
                  label: "区域名称",
                  "show-overflow-tooltip": "",
                  align: "center",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [_c("span", [_vm._v(_vm._s(scope.row.areaName))])]
                    },
                  },
                ]),
              }),
              _c("u-table-column", {
                attrs: {
                  label: "安装地址",
                  "show-overflow-tooltip": "",
                  align: "center",
                  "min-width": "160",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("span", [_vm._v(_vm._s(scope.row.installAddress))]),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }